const SurveyHRBanner = () => (
  <div className="text-green-900 text-sm text-center mb-12">
    Are you an HR leader interested in improving the managers in your organization?
    {' '}
    <a href="mailto:contact@ratemymanagementskills.com" className="whitespace-nowrap font-medium hover:text-green-700">
      Contact us <span aria-hidden="true">&rarr;</span>
    </a>
  </div>
);

export default SurveyHRBanner;
