import * as EmailValidator from 'email-validator';
import { useState } from 'react';

import { getGraphQLClient } from '@helpers/graphql'
import EmailInput from '@components/email-input/email-input'
import ErrorMessage from '@components/error-message/error-message'
import SuccessMessage from '@components/success-message/success-message'
import { classNames } from '@helpers/css'
import Title from '@components/survey/title'
import { sendMagicLinkMutation } from '@gql/login'


const SurveyMagicLinkForm = ({isLoginError}) => {
  const [formState, setFormState] = useState('initial');
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState(isLoginError);

  const handleClickLogin = () => {
    if (!EmailValidator.validate(email)) {
      return setFormState('invalid-email');
    }
    setLoginError(false);
    setFormState('loading');
    getGraphQLClient().request(sendMagicLinkMutation, { email }).then(() => {
      setFormState('check-email');
    }).catch(() => {
      setFormState('failed');
    });
  };
  return (
    <div>
      <div className="w-full sm:w-1/2">
        <Title>Log in</Title>
        {loginError ? (
            <div className="my-4">
              <ErrorMessage>You may have clicked an expired login link. Please try again.</ErrorMessage>
            </div>) : null
        }
        <EmailInput
          id="email"
          onChangeEmail={(email) => setEmail(email)}
          value={email}
          placeholder="Your email"
        />
      </div>
      <button
        className={classNames(
          "mt-4 inline-flex justify-center py-2 px-3 text-sm border border-transparent shadow-sm font-medium rounded-md text-white",
          'bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700',
          formState === 'loading' ? 'opacity-50' : '',
        )}
        disabled={formState === 'loading'}
        onClick={handleClickLogin}
      >
        Log in
      </button>
      {formState === 'invalid-email' && (
        <ErrorMessage>Please enter a valid email</ErrorMessage>
      )}
      {formState === 'check-email' && (
        <SuccessMessage>A confirmation email with a log-in link has been sent to {email}.</SuccessMessage>
      )}
      {formState === 'failed' && (
        <ErrorMessage>An unexpected error occurred. You may have mistyped your email, or may not have an account. Please
            <a href="mailto:contact@ratemymanagementskills.com"
               className="whitespace-nowrap font-medium hover:text-green-700"> contact us
            </a> for assistance
        </ErrorMessage>
      )}
    </div>
  );
};

export default SurveyMagicLinkForm;
