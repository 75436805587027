
const TextInput = ({ id, placeholder="", value, onChange, isError = false }) => (
  <input
    type="text"
    name="text"
    id={`input${id}`}
    className={`focus:ring-indigo-500 focus:border-indigo-500 block py-1 px-3 w-full sm:text-sm border rounded-md ${isError ? "border-red-600" : "border-gray-300"}`}
    placeholder={placeholder}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);

export default TextInput;
