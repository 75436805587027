import React from 'react';

export const defaultUser = {
  username: "anonymous",
  firstName: "",
  lastName: "",
  email: "",
  loggedIn: false,
};

const UserContext = React.createContext(defaultUser);

export default UserContext;
