import { reportSurveyQuestions, surveyOptionsByValue } from '@pages/data'


const SurveyReportAnswer = ({
  selectedSurveyOptions,
  onSetSurveyFormState,
}) => {
  return (
    <div>
      <p className="mb-2">Thank you for your valuable feedback! Your survey has been saved and shared anonymously with your manager.</p>
      <button
        className="text-sm text-blue-600 hover:text-blue-800 "
        onClick={() => onSetSurveyFormState('initial')}
      >
        <span aria-hidden="true">&larr;</span> Edit my answers
      </button>

      {/* Table */}
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="">
              <table className="min-w-full divide-y divide-gray-200 border">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Question
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 w-36 text-xs text-right font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportSurveyQuestions.map((question, personIdx) => (
                    <tr key={question.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-2 text-sm font-medium text-gray-800">{question.id}. {question.title}</td>
                      <td className="px-4 py-2 whitespace-nowrap text-right text-sm text-gray-600">
                        {surveyOptionsByValue[selectedSurveyOptions[question.id]]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyReportAnswer;
