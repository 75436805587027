import { classNames } from '@helpers/css'
import ErrorMessage from '@components/error-message/error-message'
import { LockClosedIcon } from '@heroicons/react/outline'


const SurveyForm = ({
  isReport,
  surveyFormState,
  surveyQuestions,
  surveyOptions,
  selectedSurveyOptions,
  onClickOption,
  onClickSubmitSurvey,
}) => (
  <div id="survey-form">
    {isReport ? (
      <div className="mb-6 text-gray-900 font-bold flex items-center">
        <LockClosedIcon className="h-4 w-4 mr-1" /> Your answers are saved and shared anonymously with your manager.
      </div>
    ) : (
      <div className="mb-2 font-medium text-xl underline">As a manager:</div>
    )}
    {surveyQuestions.map((question) => (
      <div className="mb-8" key={question.id}>
        <div className={classNames(
          "mb-2 font-medium text-gray-800",
          surveyFormState === 'need-answers' && selectedSurveyOptions[question.id] === null ? 'text-red-600' : '',
        )}>{question.id}. {question.title}</div>
        <div className="flex">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {surveyOptions.map((option, i) => (
              <button
                key={option.value}
                className={classNames(
                  "inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-gray-300 bg-gray-50 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-600 focus:border-green-600",
                  i === 0 ? "relative rounded-l-md " : '',
                  (i > 0 && i < surveyOptions.length) ? '-ml-px relative' : '',
                  i === surveyOptions.length - 1 ? '-ml-px relative rounded-r-md' : '',
                  option.value === selectedSurveyOptions[question.id] ? 'z-10 outline-none ring-1 ring-green-600 border-green-600' : '',
                )}
                onClick={() => onClickOption(question.id, option.value)}
              >
                {option.title}
              </button>
            ))}
          </span>
        </div>
      </div>
    ))}

    <div>
      <div className="flex items-center">
        <button
          className={classNames(
            "inline-flex justify-center py-3 px-4 border border-transparent shadow-sm font-medium rounded-md text-white",
            'bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700',
          )}
          onClick={onClickSubmitSurvey}
        >
          {isReport ? 'Submit answers' : 'Calculate Manager Score'}
        </button>
      </div>
      {surveyFormState === 'error' && (
        <ErrorMessage>An error occurred when submit the survey.</ErrorMessage>
      )}
      {surveyFormState === 'need-answers' && (
        <ErrorMessage>Please answer all the questions before submitting the form.</ErrorMessage>
      )}
    </div>
  </div>
);

export default SurveyForm;
