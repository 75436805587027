import { gql } from 'graphql-request';

export const sendMagicLinkMutation = gql`
  mutation sendMagicLinkMutation($email: String!){
    sendMagicLink(email: $email) {
      ok
    }
  }
`;

export const registerNewManagerMutation = gql`
  mutation registerNewManager($email: String!, $firstName: String!, $lastName: String!) {
    registerNewManager(email: $email, firstName: $firstName, lastName: $lastName) {
      user {
        id
      }
    }
  }
`;

export const meQuery = gql`
  {
    loggedIn
    me {
      id
      username
      email
      firstName
      lastName
    }
    answersAsRespondent{
      answer
      questionId
      manager {
        id
      }
    }
  }
`;
