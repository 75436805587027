import isInteger from 'lodash/isInteger'
import { InformationCircleIcon } from '@heroicons/react/outline'

import Title from '@components/survey/title'
import { surveyQuestions, defaultSurveyAnswers } from '@pages/data'
import { classNames } from '@helpers/css'


const radius = 36;
const circumference = radius * 2 * Math.PI;

const getScoreClassName = (score) => classNames(
  'text-gray-300',
  isInteger(score) && score <= 50 ? 'text-yellow-500' : '',
  isInteger(score) && score > 50 ? 'text-green-500' : '',
);

const Score = ({ score }) => (
  <div className="text-2xl text-center">
    <div x-data="scrollProgress" className="inline-flex items-center justify-center overflow-hidden rounded-full">
      <svg className="w-20 h-20">
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="40"
          cy="40"
        />
        <circle
          className={getScoreClassName(score)}
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={(circumference - score / 100 * circumference) || 0}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="40"
          cy="40"
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
        />
      </svg>
      <span className="absolute text-xl">{score}</span>
    </div>
  </div>
)

const TableScore = ({ score }) => (
  <span className={getScoreClassName(score)}>{score}</span>
);

const SurveyScore = ({
  loggedIn,
  selectedSurveyOptions,
  averageManagerScore,
  responderCount,
  allResponderCount,
  averageAnswersByReports,
  onEditAnswers,
}) => {

  // MANAGER SCORE
  const managerScore = parseInt(Object.values(selectedSurveyOptions).reduce((a, b) => a + b) / Object.values(selectedSurveyOptions).length);

  // REPORT SCORE
  const reportCount = responderCount;
  let averageAnswersGroupByQuestionId = defaultSurveyAnswers;
  if (averageAnswersByReports.length > 0) {
    averageAnswersGroupByQuestionId = averageAnswersByReports.reduce((memo, { questionId, averageAnswer }) => {
      memo[questionId] = parseInt(averageAnswer);
      return memo;
    }, {});
  }
  const reportManagerScoreValues = Object.values(averageAnswersGroupByQuestionId).filter((val) => val !== null);
  const averageReportManagerScore = reportManagerScoreValues.length
    ? parseInt(reportManagerScoreValues.reduce((a, b) => a + b) / reportManagerScoreValues.length)
    : '?';

  // RENDER
  return (
    <div>
      <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="p-4 border rounded">
          <div className="mb-1 text-center">Your Manager Score</div>
          <Score score={managerScore} />
          {loggedIn && (
            <div className="text-xs text-gray-400 italic text-center">
              <button className="text-blue-600 hover:underline" onClick={onEditAnswers}>
                Edit your answers
              </button>
            </div>
          )}
        </div>
        <div className="p-4 border rounded">
          <div className="mb-1 text-center">Manager Score by Team</div>
          <Score score={averageReportManagerScore} />
          {!loggedIn && (
            <div className="text-xs text-gray-400 italic text-center">Log in and invite your team</div>
          )}
        </div>
        <div className="p-4 border rounded">
          <div className="mb-1 text-center">Average Manager Score</div>
          <Score score={averageManagerScore} />
          <div className="text-xs text-gray-400 italic text-center">Based on {allResponderCount} response{allResponderCount === 1 ? '' : 's' }</div>
        </div>
      </div>

      {(loggedIn) && (
        <div>
          <div className="pt-8 mb-8">
          <Title>{reportCount} team member{reportCount === 1 ? '' : 's'} answered the survey</Title>
          {reportCount < 2 && (
            <div className="rounded-md bg-blue-50 p-2 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-2 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">Invite at least <span className="font-medium">2 team members</span> to answer the survey to start seeing the average score for each question.</p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col mb-12">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200 border">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Question
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2 w-32 text-xs text-right font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Your Score
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2 w-40 text-xs text-right font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Team Avg Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {surveyQuestions.map((question, personIdx) => (
                        <tr key={question.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-4 py-2 text-sm font-medium text-gray-800">{question.id}. {question.title}</td>
                          <td className="px-4 py-2 whitespace-nowrap text-right text-sm">
                            <TableScore score={selectedSurveyOptions[question.id] ?? 'N/A'} />
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-right text-sm">
                            <TableScore score={reportCount < 2 ? '?' : (averageAnswersGroupByQuestionId[question.id] ?? 'N/A')} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default SurveyScore;
