import { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import * as EmailValidator from 'email-validator';

import SurveyMagicLinkForm from '@components/survey/magic-link-form'
import SurveyRegisterManagerForm from '@components/survey/register-manager-form'
import SurveyReportForm from '@components/survey/report-form'
import SurveyHeader from '@components/survey/header'
import SurveyForm from '@components/survey/form'
import SurveyReportAnswer from '@components/survey/report-answer'
import SurveyScore from '@components/survey/score'
import SurveyHRBanner from '@components/survey/hr-banner'
import { getGraphQLClient } from '@helpers/graphql'
import { surveyOptions, reportSurveyQuestions, managerSurveyQuestions } from '@pages/data'
import { requestFeedbackMutation, saveSurveyMutation, averageAnswersQuery } from '@gql/survey'
import { registerNewManagerMutation, meQuery } from '@gql/login'
import { defaultSurveyAnswers } from './data';
import { defaultUser } from '@context/user'


export default function Survey() {

  /////////////////////////////////
  // State
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(defaultUser);
  const [surveyRespondents, setSurveyRespondents] = useState([]);
  const [averageAnswersByReports, setAverageAnswersByReports] = useState([]);
  const [responderCount, setResponderCount] = useState(0);
  const [allResponderCount, setAllResponderCount] = useState(0);
  const [averageManagerScore, setAverageManagerScore] = useState('?');
  const [surveyFormState, setSurveyFormState] = useState('initial');
  const [loginManagerFormState, setLoginManagerFormState] = useState('initial');
  const [requestFeedbackState, setRequestFeedbackState] = useState('');
  const [reportEmails, setReportEmails] = useState([]);
  const [managerEmail, setManagerEmail] = useState('');
  const [managerFirstName, setManagerFirstName] = useState('');
  const [managerLastName, setManagerLastName] = useState('');
  const [selectedSurveyOptions, setSelectedSurveyOptions] = useState(defaultSurveyAnswers);

  /////////////////////////////////
  // Computed
  const surveyFormIsValid = Object.values(selectedSurveyOptions).filter((val) => val === null).length === 0;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const managerId = params.manager_id ? parseInt(params.manager_id) : null;
  const isReport = managerId !== null && String(managerId) !== user.id;
  const isLoginError = !!params.login_error;

  /////////////////////////////////
  // Effects
  useEffect(() => {
    getGraphQLClient().request(meQuery).then(({ loggedIn, me, answersAsRespondent }) => {
      setUser({ loggedIn, ...me });

      // get current user answers on form
      const answers = isReport
        ? answersAsRespondent.filter(({ manager }) => String(me.id) !== String(manager.id))
        : answersAsRespondent.filter(({ manager }) => String(me.id) === String(manager.id));
      const answersByQuestionId = answers.reduce((memo, { questionId, answer }) => {
        memo[questionId] = answer;
        return memo;
      }, {});
      if (answers.length) {
        setSurveyFormState('done');
      }

      // load answer data
      setSelectedSurveyOptions((options) => ({ ...options, ...answersByQuestionId }));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [isReport]);

  useEffect(() => {
    if (surveyFormState === 'done' && !isReport) {
      getGraphQLClient().request(averageAnswersQuery).then(({
        averageAnswersByReports,
        responderCount: count,
        allResponderCount: allCount,
        averageAnswers,
        surveyRespondents: respondents,
      }) => {
        // Calculate average score
        const averageScore = parseInt(averageAnswers.reduce((memo, { averageAnswer }) => memo + averageAnswer, 0) / averageAnswers.length);
        setAverageManagerScore(averageScore);
        setResponderCount(count);
        setAllResponderCount(allCount);

        // Manager score by reports
        setAverageAnswersByReports(averageAnswersByReports);

        // Get email of people who already answered
        setSurveyRespondents(respondents);
      });
    }
  }, [surveyFormState, isReport]);

  /////////////////////////////////
  // Handlers
  const handleClickOption = (id, value) => {
    setSelectedSurveyOptions({
      ...selectedSurveyOptions,
      [`${id}`]: value,
    });
  };

  const handleSubmitSurvey = () => {
    if (surveyFormIsValid) {
      const answers = Object.keys(selectedSurveyOptions).map((key) => ({
        questionId: key,
        answer: selectedSurveyOptions[key],
      }))
      getGraphQLClient().request(saveSurveyMutation, { answers, managerId }).then(() => {
        setSurveyFormState('done');
      }).catch(() => {
        setSurveyFormState('error');
      })
    } else {
      setSurveyFormState('need-answers');
    }
  };

  const handleClickLogin = () => {
    if (!EmailValidator.validate(managerEmail)) {
      return setLoginManagerFormState('invalid-email');
    }
    if (managerFirstName.trim().length === 0) {
      return setLoginManagerFormState('invalid-first-name');
    }
    if (managerLastName.trim().length === 0) {
      return setLoginManagerFormState('invalid-last-name');
    }
    setLoginManagerFormState('loading');
    getGraphQLClient().request(registerNewManagerMutation, { email: managerEmail, firstName: managerFirstName, lastName: managerLastName }).then(({ registerNewManager }) => {
      if (registerNewManager.user.id) {
        setLoginManagerFormState('check-email');
      }
    }).catch(() => {
      setLoginManagerFormState('failed');
    });
  };

  const handleRequestFeedback = () => {
    const emails = uniq(reportEmails.filter((email) => EmailValidator.validate(email)));
    if (emails.length) {
      setRequestFeedbackState('loading');
      getGraphQLClient().request(requestFeedbackMutation, { emails }).then(() => {
        setRequestFeedbackState('invited');
        setReportEmails([]);
        const newReportEmails = emails.map((email) => ({ email }));
        setSurveyRespondents(uniqBy(surveyRespondents.concat(newReportEmails), ({ email }) => email));
      }).catch((e) => {
        setRequestFeedbackState('failed');
      });
    }
  };

  const handleChangeReportEmails = (i, email) => {
    const newEmails = [...reportEmails];
    newEmails[i] = email;
    setReportEmails(newEmails);
  };

  const handleShowMagicLinkForm = () => {
    setSurveyFormState('login');
  };

  const handleScrollToSurvey = () => {
    document.getElementById("survey-form").scrollIntoView({behavior: "smooth"});
  }

  /////////////////////////////////
  // Render
  if (isLoading) {
    return null;
  }
  return (
    <main>
      {(surveyFormState !== 'login' && !isLoginError && surveyFormState !== 'done') ? (
         (user.loggedIn && isReport) ? (
          <div className="max-w-6xl mx-auto py-2 sm:py-6 sm:pb-12 sm:px-6 lg:px-8">
            <SurveyHeader
              isReport={isReport}
              isShowingLoginLink={surveyFormState === 'initial'}
              loggedIn={user.loggedIn}
              onClickLogin={handleShowMagicLinkForm}
            />
            <div className="py-16 text-center">
              <div className="text-5xl mb-6 font-bold text-gray-800 leading-tight">
                Help your manager become a better leader.
              </div>
              <div className="mb-2 text-xl text-gray-600">
                Use this survey to <span className="font-bold">anonymously</span> share feedback with your manager to help them identify their strengths and weaknesses.
              </div>
              <div className="text-xs text-gray-400">Based on <a className="underline" href="https://rework.withgoogle.com/subjects/managers/">Google's Project Oxygen</a></div>
            </div>
          </div>
        ) : (
          <div className="max-w-7xl mx-auto py-2 sm:py-6 sm:pb-12 sm:px-6 lg:px-8 flex flex-1 flex-col justify-between h-screen">
            <SurveyHeader
              isReport={isReport}
              isShowingLoginLink={surveyFormState === 'initial'}
              loggedIn={user.loggedIn}
              onClickLogin={handleShowMagicLinkForm}
            />
            <div className="flex flex-col w-full items-center px-4 md:flex-row md:p-0">
              <div className="flex-1">
                <div className="text-3xl md:text-4xl lg:text-5xl mb-4 font-bold text-gray-800 tracking-tight lg:leading-tight">
                  Managers matter.<br />
                  Become a better leader<br />
                  with help from your team.
                </div>
                <div className="mb-8 text-gray-600">
                  A survey tool to compare feedback from your team with your self-assessment.
                </div>
                <div className="mb-2 text-gray-700">
                  <button
                    className="bg-green-900 font-medium text-white px-12 py-4 text-lg rounded"
                    onClick={handleScrollToSurvey}
                  >
                    Take the survey
                  </button>
                </div>
                <div className="text-xs text-gray-400 mb-6">Based on <a className="underline" href="https://rework.withgoogle.com/subjects/managers/">Google's Project Oxygen</a></div>
              </div>
              <img alt="homepage illustration" src={`${window.staticPath}/static/homepage-illustration.png`} className="block w-full md:w-1/2" />
            </div>
            <div>{' '}</div>
          </div>
        )
      ) : (
        <div className="max-w-7xl mx-auto py-2 sm:py-6 sm:pb-12 sm:px-6 lg:px-8">
          <SurveyHeader
            isReport={isReport}
            isShowingLoginLink={surveyFormState === 'initial'}
            loggedIn={user.loggedIn}
            onClickLogin={handleShowMagicLinkForm}
          />
        </div>
      )}

      <div className="max-w-4xl mx-auto sm:px-6 lg:px-8">
        <div className="p-4 sm:p-8 bg-white border sm:rounded sm:shadow mb-8">
          {surveyFormState === 'login' || isLoginError ? (
            <SurveyMagicLinkForm isLoginError={isLoginError} />
          ) : surveyFormState !== 'done' ? ( // initial/error/need-answers state
            <SurveyForm
              isReport={isReport}
              surveyFormState={surveyFormState}
              surveyQuestions={isReport ? reportSurveyQuestions : managerSurveyQuestions}
              surveyOptions={surveyOptions}
              selectedSurveyOptions={selectedSurveyOptions}
              onClickOption={handleClickOption}
              onClickSubmitSurvey={handleSubmitSurvey}
            />
          ) : isReport ? (
            <SurveyReportAnswer
              onSetSurveyFormState={setSurveyFormState}
              selectedSurveyOptions={selectedSurveyOptions}
            />
          ) : (
            <div>
              <SurveyScore
                loggedIn={user.loggedIn}
                selectedSurveyOptions={selectedSurveyOptions}
                averageManagerScore={averageManagerScore}
                responderCount={responderCount}
                allResponderCount={allResponderCount}
                averageAnswersByReports={averageAnswersByReports}
                onEditAnswers={() => setSurveyFormState('initial')}
              />

              {user.loggedIn ? (
                <SurveyReportForm
                  requestFeedbackState={requestFeedbackState}
                  reportEmails={reportEmails}
                  surveyRespondents={surveyRespondents}
                  onChangeReportEmails={handleChangeReportEmails}
                  onClickRequestFeedback={handleRequestFeedback}
                />
              ) : (
                <SurveyRegisterManagerForm
                  loginManagerFormState={loginManagerFormState}
                  managerFirstName={managerFirstName}
                  managerLastName={managerLastName}
                  managerEmail={managerEmail}
                  onSetManagerFirstName={setManagerFirstName}
                  onSetManagerLastName={setManagerLastName}
                  onSetManagerEmail={setManagerEmail}
                  onClickLoginManager={handleClickLogin}
                />
              )}
            </div>
          )}
        </div>
        <SurveyHRBanner />
      </div>
    </main>
  )
};
