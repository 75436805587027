import { gql } from 'graphql-request';


export const requestFeedbackMutation = gql`
mutation requestFeedback($emails: [String]!) {
  requestResponders(responderEmails: $emails){
    ok
  }
}`;

export const saveSurveyMutation = gql`
mutation saveSurvey($answers: [SurveyAnswer]!, $managerId: Int) {
  answerSurvey(answers: $answers, managerId: $managerId) {
    ok
  }
}
`;

export const averageAnswersQuery = gql`
{
  averageAnswers {
    questionId
    averageAnswer
  }
  averageAnswersByReports {
    questionId
    averageAnswer
  }
  responderCount
  allResponderCount
  surveyRespondents {
    email
  }
}
`;
