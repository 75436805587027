import EmailInput from '@components/email-input/email-input'
import TextInput from '@components/text-input/text-input'
import ErrorMessage from '@components/error-message/error-message'
import SuccessMessage from '@components/success-message/success-message'
import Title from '@components/survey/title'
import { classNames } from '@helpers/css'


const SurveyRegisterManagerForm = ({
  loginManagerFormState,
  managerFirstName,
  managerLastName,
  managerEmail,
  onSetManagerFirstName,
  onSetManagerLastName,
  onSetManagerEmail,
  onClickLoginManager,
}) => (
  <div className="border-t pt-8">
    <Title>Sign up to compare results</Title>
    <p className="mb-4">Save your score and request feedback from your team. It's free!</p>
    {loginManagerFormState !== 'check-email' && (
      <div>
        <div className="w-full sm:w-1/2 mb-2">
          <TextInput
            id="manager-first-name"
            onChange={(name) => onSetManagerFirstName(name)}
            value={managerFirstName}
            placeholder="Your first name"
            isError={loginManagerFormState === 'invalid-first-name'}
            />
        </div>
        <div className="w-full sm:w-1/2 mb-2">
          <TextInput
            id="manager-last-name"
            onChange={(name) => onSetManagerLastName(name)}
            value={managerLastName}
            placeholder="Your last name"
            isError={loginManagerFormState === 'invalid-last-name'}
            />
        </div>
        <div className="w-full sm:w-1/2">
          <EmailInput
            id="manager-email"
            onChangeEmail={(email) => onSetManagerEmail(email)}
            value={managerEmail}
            placeholder="Your email"
            isError={loginManagerFormState === 'invalid-email'}
          />
        </div>
        <button
          className={classNames(
            "mt-4 inline-flex justify-center py-2 px-3 text-sm border border-transparent shadow-sm font-medium rounded-md text-white",
            'bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700',
            loginManagerFormState === 'loading' ? 'opacity-50' : '',
          )}
          disabled={loginManagerFormState === 'loading'}
          onClick={onClickLoginManager}
        >
          Sign up
        </button>
      </div>
    )}
    {loginManagerFormState === 'invalid-first-name' && (
      <ErrorMessage>Please enter your first name</ErrorMessage>
    )}
    {loginManagerFormState === 'invalid-last-name' && (
      <ErrorMessage>Please enter your last name</ErrorMessage>
    )}
    {loginManagerFormState === 'invalid-email' && (
      <ErrorMessage>Please enter a valid email</ErrorMessage>
    )}
    {loginManagerFormState === 'failed' && (
      <ErrorMessage>An error occurred while saving your manager score.</ErrorMessage>
    )}
    {loginManagerFormState === 'check-email' && (
      <SuccessMessage>A confirmation link has been sent to {managerEmail}.</SuccessMessage>
    )}
  </div>
);

export default SurveyRegisterManagerForm;
