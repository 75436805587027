
const EmailInput = ({ id, placeholder="report@example.com", value, onChangeEmail, isError = false }) => (
  <input
    type="email"
    name="email"
    id={`email${id}`}
    className={`focus:ring-indigo-500 focus:border-indigo-500 block py-1 px-3 w-full sm:text-sm border rounded-md ${isError ? "border-red-600" : "border-gray-300"}`}
    placeholder={placeholder}
    value={value}
    onChange={(e) => onChangeEmail(e.target.value)}
  />
);

export default EmailInput;
