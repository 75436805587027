import range from 'lodash/range';

import Title from '@components/survey/title'
import EmailInput from '@components/email-input/email-input'
import ErrorMessage from '@components/error-message/error-message'
import SuccessMessage from '@components/success-message/success-message'
import { classNames } from '@helpers/css'


const SurveyReportForm = ({
  requestFeedbackState,
  reportEmails,
  surveyRespondents,
  onChangeReportEmails,
  onClickRequestFeedback,
}) => (
  <div className="border-t pt-8">
    <Title>Send survey to your team</Title>
    <div>
    Enter the team member emails and they will receive an invitation to anonymously fill out this survey.
    </div>
    <div className="w-full sm:w-1/2">
      {range(0, reportEmails.length + 1).map((i) => (
        <div className="mt-2" key={i}>
          <EmailInput
            id={i}
            onChangeEmail={(email) => onChangeReportEmails(i, email)}
            value={reportEmails[i] || ''}
            />
        </div>
      ))}
    </div>
    <button
      className={classNames(
        "mt-4 inline-flex justify-center py-2 px-3 text-sm border border-transparent shadow-sm font-medium rounded-md text-white",
        'bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700',
        requestFeedbackState === 'loading' ? 'opacity-50' : '',
        )}
        onClick={onClickRequestFeedback}
        disabled={requestFeedbackState === 'loading'}
        >
      {requestFeedbackState === 'loading' ? 'Loading...' : 'Send survey'}
    </button>
    {requestFeedbackState === 'invalid-email-reports' && (
      <ErrorMessage>The emails are invalid.</ErrorMessage>
    )}
    {requestFeedbackState === 'invited' && (
      <SuccessMessage>Invitations have been sent.</SuccessMessage>
    )}
    {surveyRespondents.length > 0 && (
      <div className="mt-8">
        <div className="underline">Already invited</div>
        <ul className="list-disc ml-4">
          {surveyRespondents.map(({ email }) => (
            <li key={email} className="text-gray-700 text-sm">{email}</li>
          ))}
        </ul>
      </div>
    )}

  </div>
);

export default SurveyReportForm;
