export const people = [
  {
    id: 1,
    name: 'Maxime Parmentier',
    title: 'Front-end Developer',
    department: 'Development Team',
    role: 'Direct Report',
    email: 'maxime@manageros.com',
    image:
      'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    id: 2,
    name: 'Tony Angerilli',
    title: 'Back-end Developer',
    department: 'Development Team',
    role: 'Employee',
    email: 'tony@manageros.com',
    image:
      'https://images.unsplash.com/photo-1580518324671-c2f0833a3af3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
]

export const oneonones = [
  {
    id: 5,
    title: 'Quarterly 1-on-1',
    created: 'Jul 3rd, 2021'
  },
  {
    id: 4,
    title: 'Monthly 1-on-1',
    created: 'Jun 7th, 2021'
  },
  {
    id: 3,
    title: 'Monthly 1-on-1',
    created: 'May 6th, 2021'
  },
  {
    id: 2,
    title: 'Quaterly 1-on-1',
    created: 'Apr 3rd, 2021'
  },
  {
    id: 1,
    title: 'Monthly 1-on-1',
    created: 'Mar 6th, 2021'
  },
];

export const goals = [
  {
    id: 5,
    title: 'Increase number of code reviews by 50%',
    created: 'Jun 15th, 2021',
    targetValue: 20,
    currentValue: 15,
  },
  {
    id: 6,
    title: 'Decrease number of comments on my PR by simplifying and decreasing LOC',
    created: 'Apr 19th, 2021',
    targetValue: 5,
    currentValue: 5,
  },
];

export const tasks = [
  {
    id: 1,
    title: 'Find online courses to learn React',
    completed: false,
  },
  {
    id: 2,
    title: 'Complete security compliance training',
    completed: false,
  },
  {
    id: 3,
    title: 'Do pair programming session with Jonas',
    completed: false,
  },
  {
    id: 4,
    title: 'Attend Vancouver Dev Manager meetup',
    completed: false,
  },
  {
    id: 5,
    title: 'Do pair programming session with Gert',
    completed: true,
  },
];

export const career = {
  tasks: [
    {
      id: 1,
      title: 'Have coffee chat with one Principal Engineer to learn more about role',
    },
    {
      id: 2,
      title: 'Take SCRUM training',
    },
  ],
  comments: [
    {
      id: 2,
      comment: 'Awesome! Yes please, I\'d love an intro to go for coffee with him and talk about the role.',
      author: 'Me',
      created: 'Today',
    },
    {
      id: 1,
      comment: 'You should talk to Rob. He has been a principal engineer at ManagerOS for 2 years and was already a principal at his previous company. You want me to make an intro?',
      author: 'Amin (Your Manager)',
      created: 'Yesterday',
    },
  ],
};

export const githubPR = {
  tasks: [
    {
      id: 1,
      title: 'Review CI process',
    },
    {
      id: 3,
      title: '',
    },
  ],
  comments: [
    {
      id: 2,
      comment: 'Awesome! Yes please, I\'d love an intro to go for coffee with him and talk about the role.',
      author: 'Me',
      created: 'Today',
    },
    {
      id: 1,
      comment: 'You should talk to Rob. He has been a principal engineer at ManagerOS for 2 years and was already a principal at his previous company. You want me to make an intro?',
      author: 'Amin (Your Manager)',
      created: 'Yesterday',
    },
  ],
};



export const user = {
  id: 1,
  name: 'Tony Angerilli',
  title: 'CTO',
  department: 'Development Team',
  email: 'tony@manageros.com',
  image: 'https://images.unsplash.com/photo-1576558656222-ba66febe3dec?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  manager: {
    id: 2,
    name: 'Amin Palizban',
    role: 'Manager',
    title: 'CEO',
    department: 'Executive Team',
    email: 'amin@manageros.com',
    image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  reports: [{
    id: 3,
    name: 'Maxime Parmentier',
    role: 'Report',
    title: 'Front-end Developer',
    department: 'Development Team',
    email: 'maxime@manageros.com',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  }, {
    id: 4,
    name: 'Jessica Roberts',
    role: 'Report',
    title: 'Back-end Developer',
    department: 'Development Team',
    email: 'tony@manageros.com',
    image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  }],
};

export const feed = [{
  id: 1,
  action: 'posted',
  body: 'Hello! How are things going? With the recent vacations we haven\'t met for a while, should we do a 1-on-1 to catch up?',
  date: 'Today ago',
  replies: [],
}, {
  id: 2,
  action: 'posted',
  body: 'I\'m going on vacation for 2 weeks. Let\'s chat again about my career goal when I get back.',
  date: '3 weeks ago',
  replies: [{
    id: 7,
    body: 'Sure that works!'
  }]
}, {
  id: 3,
  action: 'completed a 1-on-1',
  body: 'I\'m going on vacation for 2 weeks. Let\'s chat again about my career goal when I get back.',
  date: '2 months ago',
  replies: [],
  actionMetadata: {
    type: '1-on-1',
    name: 'Quaterly 1-on-1',
    url: '/'
  }
}, {
  id: 5,
  action: 'created a goal',
  body: 'As mentioned during our last 1-on-1, here is the new goal you should focus on for the next couple months.',
  date: '3 months ago',
  replies: [],
  actionMetadata: {
    type: 'Goal',
    name: goals[0].title,
    url: '/'
  }
}];

export const githubStats = [
  { id: 1, name: 'Code Reviews', stat: '25', previousStat: '23', change: '+2', changeType: 'increase' },
  { id: 2, name: 'Pull Requests', stat: '16', previousStat: '21', change: '-5', changeType: 'decrease' },
];

export const managerSurveyQuestions = [{
  id: 1,
  title: 'I am a good coach',
}, {
  id: 2,
  title: 'I empower my team and do not micromanage',
}, {
  id: 3,
  title: 'I create an inclusive team environment, showing concern for success and well-being',
}, {
  id: 4,
  title: 'I am productive and results-oriented',
}, {
  id: 5,
  title: 'I am a good communicator - listens and shares information',
}, {
  id: 6,
  title: 'I support career development and discuss performance',
}, {
  id: 7,
  title: 'I have a clear vision/strategy for the team',
}, {
  id: 8,
  title: 'I have key technical skills to help advise the team',
}, {
  id: 9,
  title: 'I collaborate across the company',
}, {
  id: 10,
  title: 'I am a strong decision maker',
}];

export const reportSurveyQuestions = [{
  id: 1,
  title: 'My manager is a good coach',
}, {
  id: 2,
  title: 'My manager empowers the team and does not micromanage',
}, {
  id: 3,
  title: 'My manager creates an inclusive team environment, showing concern for success and well-being',
}, {
  id: 4,
  title: 'My manager is productive and results-oriented',
}, {
  id: 5,
  title: 'My manager is a good communicator - listens and shares information',
}, {
  id: 6,
  title: 'My manager supports career development and discusses performance',
}, {
  id: 7,
  title: 'My manager has a clear vision/strategy for the team',
}, {
  id: 8,
  title: 'My manager has key technical skills to help advise the team',
}, {
  id: 9,
  title: 'My manager collaborates across the company',
}, {
  id: 10,
  title: 'My manager is a strong decision maker',
}];

export const surveyQuestions = [{
  id: 1,
  title: 'Is a good coach',
}, {
  id: 2,
  title: 'Empowers the team and does not micromanage',
}, {
  id: 3,
  title: 'Creates an inclusive team environment, showing concern for success and well-being',
}, {
  id: 4,
  title: 'Is productive and results-oriented',
}, {
  id: 5,
  title: 'Is a good communicator - listens and shares information',
}, {
  id: 6,
  title: 'Supports career development and discusses performance',
}, {
  id: 7,
  title: 'Has a clear vision/strategy for the team',
}, {
  id: 8,
  title: 'Has key technical skills to help advise the team',
}, {
  id: 9,
  title: 'Collaborate across the company',
}, {
  id: 10,
  title: 'Is a strong decision maker',
}];

export const surveyOptions = [
  { value: 0, title: 'Strongly disagree', },
  { value: 25, title: 'Disagree', },
  { value: 50, title: 'Neutral', },
  { value: 75, title: 'Agree', },
  { value: 100, title: 'Strongly agree', },
];

export const surveyOptionsByValue = {
  0: 'Strongly disagree',
  25: 'Disagree',
  50: 'Neutral',
  75: 'Agree',
  100: 'Strongly agree',
};

export const defaultSurveyAnswers = {
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
  7: null,
  8: null,
  9: null,
  10: null,
};
