import { BadgeCheckIcon } from '@heroicons/react/outline'


const SurveyHeader = ({
  isShowingLoginLink,
  loggedIn,
  onClickLogin,
}) => (
  <div className="px-4 sm:p-0 sm:flex sm:justify-between sm:items-center mb-2">
    <div className="flex items-center mb-2 sm:mb-0">
      <BadgeCheckIcon className="h-5 w-5 sm:h-7 sm:w-7 mr-2 text-green-500" aria-hidden="true" />
      <a href="/" className="block text-lg sm:text-xl font-medium text-gray-700 hover:text-black">Rate My Management Skills</a>
    </div>
    {isShowingLoginLink && !loggedIn && (
      <div className="text-right">
        <button className="text-gray-700 font-bold text-sm" onClick={onClickLogin}>Log in</button>
      </div>
    )}
  </div>
);

export default SurveyHeader;
